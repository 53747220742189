// CardList.js
import React from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import axios from "../Api";

const CardList = ({ cards, fetchCards, setSelectedCard }) => {
  const handleDelete = async (id) => {
    await axios.delete(`/free_fire/${id}`);
    fetchCards();
  };

  return (
    <Row>
      {cards.map((card) => (
        <Col md={4} className="movieWrapper mb-4" key={card.id}>
          <Card className="movieImage movieCard">
            <Card.Img
              variant="top"
              src={`https://wikipediagames.wikipediagames.com/storage/${card.image}`}
              alt={`${card.title} Movies`}
              className="images"
            />
            <Card.Body className="bg-dark p-2 text-white">
              <Card.Title className="text-center">{card.title}</Card.Title>
              <Card.Text className="text-left pre-wrap-text">
                {card.description}
              </Card.Text>

              <Card.Text className="text-left">
                Created at :{" "}
                {(() => {
                  const date = new Date(card.created_at);
                  const time = date.toLocaleTimeString("en-GB", {
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                  });
                  const formattedDate = date.toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  });
                  return `${time} (${formattedDate})`;
                })()}
              </Card.Text>
              <Card.Text className="text-left">
                Updated at :{" "}
                {(() => {
                  const date = new Date(card.updated_at);
                  const time = date.toLocaleTimeString("en-GB", {
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                  });
                  const formattedDate = date.toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  });
                  return `${time} (${formattedDate})`;
                })()}
              </Card.Text>
              <div className="d-flex justify-content-between mt-3">
                <Button variant="warning" onClick={() => setSelectedCard(card)}>
                  Edit
                </Button>
                <Button variant="danger" onClick={() => handleDelete(card.id)}>
                  Delete
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default CardList;
