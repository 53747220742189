import React, { useState, useEffect } from "react";
import { Form, Button, Container } from "react-bootstrap";
import axios from "../Api";

const CardForm = ({   fetchCards,
  selectedCard,
  setSelectedCard,
  addCardToState,
}) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (selectedCard) {
      setTitle(selectedCard.title);
      setDescription(selectedCard.description);
      setImage(null);
    }
  }, [selectedCard]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    if (image) formData.append("image", image);

    try {
      let response;
      if (selectedCard) {
        response = await axios.post(
          `/mobile_legend/${selectedCard.id}?_method=PUT`,
          formData
        );
      } else {
        response = await axios.post("/mobile_legend", formData);
        addCardToState(response.data); // Add the new card to state
      }
      fetchCards();
      setTitle("");
      setDescription("");
      setImage(null);
      setSelectedCard(null);
    } catch (error) {
      console.error("Error saving card:", error);
    }
  };


  return (
    <Container className="px-3">
      <Form onSubmit={handleSubmit} className="w-100">
        <Form.Group controlId="formTitle">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            className="mb-3"
          />
        </Form.Group>
        <Form.Group controlId="formDescription">
          <Form.Label>Description</Form.Label>
          <Form.Control
            placeholder="Enter description"
            as="textarea"
            rows={6}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            style={{ resize: "none" }}
            required
            className="mb-3"
          />
        </Form.Group>
        <Form.Group controlId="formImage">
          <Form.Label>Image</Form.Label>
          <Form.Control
            type="file"
            onChange={(e) => setImage(e.target.files[0])}
            className="mb-3"
          />
        </Form.Group>
        <Button variant="primary" type="submit" className="mt-3 w-100">
          {selectedCard ? "Update Content" : "Add Content"}
        </Button>
      </Form>
    </Container>
  );
};

export default CardForm;
