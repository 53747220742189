import React, { useState, useEffect } from "react";
import axios from "../Api";
import CardForm from "../components_card/CardForm";
import CardList from "../components_card/CardList";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container } from "react-bootstrap";
import "./menu.css"; // Import custom CSS for additional styling if needed

const Menu = () => {
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);

  const fetchCards = async () => {
    try {
      const response = await axios.get("/cards");
      setCards(response.data);
    } catch (error) {
      console.error("Error fetching cards:", error);
    }
  };

  useEffect(() => {
    fetchCards();
  }, []);

  const addCardToState = (newCard) => {
    setCards((prevCards) => [newCard, ...prevCards]);
  };

  return (
    <Container className="my-4">
      <div className="bg-light p-4 rounded shadow-sm">
        <CardForm
          fetchCards={fetchCards}
          selectedCard={selectedCard}
          setSelectedCard={setSelectedCard}
          addCardToState={addCardToState} // Pass the addCardToState function
        />
        <br />
        <div className="card-list-container">
          <CardList
            cards={cards}
            fetchCards={fetchCards}
            setSelectedCard={setSelectedCard}
          />
        </div>
      </div>
    </Container>
  );
};

export default Menu;
