import { useEffect, useState } from "react";
import {
  Table,
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "../Api"; // Correctly importing axios instance
import "../../style/content.css";

function AdminCommentData() {
  const navigate = useNavigate();
  const [comments, setComments] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(true); // Assume user is authenticated initially

  useEffect(() => {
    const checkAuthentication = () => {
      // Checking authentication token for admin access
      const token = localStorage.getItem("authToken"); // Example: checking token
      if (!token) {
        setIsAuthenticated(false);
        navigate("/LoginForm"); // Redirect to login if not authenticated
      }
    };

    checkAuthentication();

    if (isAuthenticated) {
      // Fetch the admin account details from the API
      const fetchAdminAccount = async () => {
        const token = localStorage.getItem("authToken");
        try {
          const response = await axios.get("/admin-account", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (response.status === 200) {
            const data = response.data;
            if (data.role !== "admin") {
              navigate("/UserDashboard"); // Redirect to user dashboard if not admin
            } else {
              setIsAuthenticated(true); // Set as authenticated only if admin
            }
          } else {
            console.error(
              "Failed to fetch admin account details:",
              response.statusText
            );
            navigate("/LoginForm");
          }
        } catch (error) {
          console.error(
            "Failed to fetch admin account details:",
            error.message
          );
          navigate("/LoginForm");
        }
      };

      fetchAdminAccount();
    }

    // Fetch comments data from the API
    const fetchCommentsData = async () => {
      try {
        const response = await axios.get("/get_dataComments", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        });

        setComments(response.data);
      } catch (err) {
        console.error("Failed to fetch comments data:", err.message);
      }
    };

    fetchCommentsData();
  }, [isAuthenticated, navigate]);

  const deleteComments = async (id) => {
    try {
      const token = localStorage.getItem("authToken");
      // Call the delete API endpoint
      await axios.delete(`/delete_comment/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      alert("Comment deleted successfully!");
      setComments((prevComments) =>
        prevComments.filter((acc) => acc.id !== id)
      ); // Update state after deletion
    } catch (error) {
      alert("Error during deletion!");
      console.error("There was an error during deletion!", error);
    }
  };

  return (
    <Container className="py-5">
      <Row className="justify-content-center">
        <Col xs={12} md={10}>
          <Card className="shadow">
            <Card.Body>
              <Card.Title className="text-center mb-4">
                <b>ALL COMMENTS GUEST WIKIPEDIA GAMES</b>
              </Card.Title>
              <Table striped bordered hover responsive="sm">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Comment</th>
                    <th>Action</th>
                    <th>Create at</th>
                  </tr>
                </thead>
                <tbody>
                  {comments.map((bk) => (
                    <tr key={bk.id}>
                      <td>{bk.id}</td>
                      <td>{bk.name}</td>
                      <td className="pre-wrap-text">{bk.comment}</td>
                      <td>
                        <Form>
                          <div className="m-1">
                            <button
                              className="btn btn-danger mt-1"
                              type="button"
                              onClick={() => deleteComments(bk.id)} // Pass the comment id to the function
                            >
                              Delete
                            </button>
                          </div>
                        </Form>
                      </td>
                      <td>
                        {(() => {
                          const date = new Date(bk.created_at);
                          const time = date.toLocaleTimeString("en-GB", {
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                          });
                          const formattedDate = date.toLocaleDateString(
                            "en-GB",
                            {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            }
                          );
                          return `${time} (${formattedDate})`;
                        })()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
          <Col className="d-flex justify-content-center m-4">
            <Button className="btn btn-primary" href="/AdminDashboard">
              Back to Dashboard
            </Button>
          </Col>
        </Col>
      </Row>
    </Container>
  );
}

export default AdminCommentData;
