import { Container, Row, Col, Card, Image } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import ImageStorage from "../ImageStorage";
import axios from "../Api";
import "../../style/menu.css";

const FreeFire = () => {
  const [cards, setCards] = useState([]);

  const fetchCards = async () => {
    try {
      const response = await axios.get("/free_fire");
      setCards(response.data);
    } catch (error) {
      console.error("Error fetching cards:", error);
    }
  };

  useEffect(() => {
    fetchCards();
  }, []);

  return (
    <div className="superhero-section py-5">
      <Container>
        <h1 className="text-white titleBody text-center mb-4">FREE FIRE</h1>
        <Row>
          {cards.map((card) => (
            <Col md={4} className="movieWrapper mb-4" key={card.id}>
              <Card className="movieImage movieCard">
                <ImageStorage imagePath={card.image} />
                <div className="bg-dark p-2 text-white">
                  <Card.Title className="text-center">{card.title}</Card.Title>
                  <Card.Text className="text-left pre-wrap-text">
                    {card.description}
                  </Card.Text>
                  <Card.Text className="text-center visually-hidden">
                    Last updated {card.updated_at}
                  </Card.Text>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default FreeFire;
