// CardList.js
import React from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import axios from "../Api";

const CardList = ({ cards, fetchCards, setSelectedCard }) => {
  const handleDelete = async (id) => {
    await axios.delete(`/cards/${id}`);
    fetchCards();
  };

  return (
    <Row>
      {cards.map((card) => (
        <Col md={4} className="movieWrapper mb-4" key={card.id}>
          <Card className="movieImage movieCard">
            <Card.Img
              variant="top"
              src={`http://localhost:8001/storage/${card.image}`}
              alt={`${card.title} Movies`}
              className="images"
            />
            <Card.Body className="bg-dark p-2 text-white">
              <Card.Title className="text-center">{card.title}</Card.Title>
              <Card.Text className="text-left pre-wrap-text">{card.description}</Card.Text>

              <Card.Text className="text-left">Created at : {card.created_at}</Card.Text>
              <Card.Text className="text-left">Update at : {card.updated_at}</Card.Text>
              <Button variant="danger" onClick={() => handleDelete(card.id)}>
                Delete
              </Button>
              <Button variant="warning" onClick={() => setSelectedCard(card)}>
                Edit
              </Button>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default CardList;
