import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Image,
  Form,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import axios from "../Api"; // Assuming you have an axios instance with API base URL

const AccountUpdateForm = () => {
  const [userAccount, setUserAccount] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [editFormData, setEditFormData] = useState({
    username: "",
    name: "",
    gender: "",
    bornplace: "",
    birthdate: "",
    phonenumber: "",
    address: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthentication = () => {
      const token = localStorage.getItem("authToken");
      if (!token) {
        setIsAuthenticated(false);
        navigate("/LoginForm");
      } else {
        setIsAuthenticated(true);
      }
    };

    checkAuthentication();

    if (isAuthenticated) {
      const fetchUserAccount = async () => {
        try {
          const token = localStorage.getItem("authToken");
          const response = await axios.get("/user-account", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          setUserAccount(response.data);
          setEditFormData({
            username: response.data.username,
            name: response.data.name,
            gender: response.data.gender,
            bornplace: response.data.bornplace,
            birthdate: response.data.birthdate,
            phonenumber: response.data.phonenumber,
            address: response.data.address,
          });

          if (response.data.role !== "user") {
            alert("The username you input is Admin.");
            navigate("/UserDashboard");
          }
        } catch (error) {
          console.error("Failed to fetch user account details:", error);
          navigate("/LoginForm");
        }
      };

      fetchUserAccount();
    }
  }, [isAuthenticated, navigate]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.size > 2048 * 1024) {
      alert("File size exceeds 2MB.");
      setSelectedFile(null);
    } else {
      setSelectedFile(file);
    }
  };

  const handleImageUpload = async () => {
    if (!selectedFile) {
      alert("Please select a file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("display_picture", selectedFile);

    try {
      const token = localStorage.getItem("authToken");
      const response = await axios.post("/upload-image", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        setUserAccount((prevAccount) => ({
          ...prevAccount,
          display_picture: response.data.file_path,
        }));
        alert("Profile picture uploaded successfully!");
      }
    } catch (error) {
      console.error("Error uploading profile picture:", error);
      alert("Failed to upload the profile picture.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditFormData({ ...editFormData, [name]: value });
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("authToken");

    if (!userAccount) {
      alert("User account not found!");
      return;
    }

    try {
      const response = await axios.put(
        `/user-account/${userAccount.id}`,
        editFormData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // Update both userAccount and editFormData with the response
      setUserAccount(response.data);
      setEditFormData({
        // username: response.data.username,
        name: response.data.name,
        gender: response.data.gender,
        bornplace: response.data.bornplace,
        birthdate: response.data.birthdate,
        phonenumber: response.data.phonenumber,
        address: response.data.address,
      });

      alert("Account edited successfully!");
    } catch (error) {
      alert(
        "Error during editing: " +
          (error.response?.data?.message || "Please try again.")
      );
      console.error("Error during editing:", error);
    }
  };

  return (
    <div className="page-wrapper">
      <Container fluid className="content p-3">
        <h1
          className="text-center text-light mt-2"
          style={{
            fontFamily: "Tahoma",
            fontWeight: "bold",
            textShadow: "2px 2px 4px #000000",
          }}
        >
          <br />
          <br />
          <b>USER DASHBOARD EDIT</b>
        </h1>
        <Row className="d-flex justify-content-center align-items-center">
          <Col xs={12} md={6} lg={6} className="mb-4">
            <Card
              className="shadow-sm d-flex justify-content-center align-items-center text-white"
              style={{
                backgroundColor: "rgba(200, 200, 200, 0.3)",
                border: "1px solid rgba(255,255,255,1.0)",
              }}
            >
              <Card.Title
                className="text-center accountupdateform mt-0"
                style={{
                  fontFamily: "Tahoma",
                  fontWeight: "bold",
                  textShadow: "2px 2px 4px #000000",
                }}
              >
                <b>User Account Details</b>
              </Card.Title>
              <Card.Body
                style={{ maxWidth: "750px", fontFamily: "TimesnewRoman" }}
              >
                {userAccount ? (
                  <Card.Text>
                    <Row>
                      <Col className="text-center p-3 m-2">
                        <Image
                          src={
                            "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?size=626&ext=jpg" ||
                            userAccount.display_picture
                          }
                          className="me-1"
                          width={125}
                          height={125}
                          rounded
                        />
                        <Form.Group controlId="formFile">
                          <Form.Label>Select a file:</Form.Label>
                          <Form.Control
                            type="file"
                            onChange={handleFileChange}
                          />
                        </Form.Group>
                        <Button
                          variant="primary"
                          onClick={handleImageUpload}
                          className="mt-3"
                        >
                          Upload
                        </Button>
                      </Col>
                      <Col className="d-flex justify-content-center align-items-center">
                        <div
                          className="mx-auto p-3 m-2"
                          style={{
                            border: "1px solid rgba(255,255,255,1.0)",
                            borderRadius: "10px",
                            backgroundColor: "rgba(0, 0, 0, 0.38)",
                            fontFamily: "Tahoma",
                            textShadow: "2px 2px 4px #000000",
                            width: "300px",
                          }}
                        >
                          <Form onSubmit={handleEditSubmit}>
                            <Form.Group className="text-center">
                              <Form.Label>
                                ( <b>Account Number ID : [{userAccount?.id}]</b>{" "}
                                )
                              </Form.Label>
                            </Form.Group>
                            <Form.Group controlId="formUsername">
                              <Form.Label>Username</Form.Label>
                              <Form.Control
                                type="text"
                                name="username"
                                value={editFormData.username}
                                disabled
                              />
                            </Form.Group>
                            <Form.Group controlId="formName">
                              <Form.Label>Name</Form.Label>
                              <Form.Control
                                type="text"
                                name="name"
                                value={editFormData.name}
                                onChange={handleInputChange}
                              />
                            </Form.Group>
                            <Form.Group
                              controlId="formGender"
                              className="mb-2 mt-2"
                            >
                              <Form.Label>Gender</Form.Label>
                              <Form.Select
                                name="gender"
                                value={editFormData.gender}
                                onChange={handleInputChange}
                              >
                                <option value="">Select Gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                              </Form.Select>
                            </Form.Group>
                            <Form.Group controlId="formBornplace">
                              <Form.Label>Bornplace</Form.Label>
                              <Form.Control
                                type="text"
                                name="bornplace"
                                value={editFormData.bornplace}
                                onChange={handleInputChange}
                              />
                            </Form.Group>
                            <Form.Group controlId="formBirthdate">
                              <Form.Label>Birthdate</Form.Label>
                              <Form.Control
                                type="date"
                                name="birthdate"
                                value={editFormData.birthdate}
                                onChange={handleInputChange}
                              />
                            </Form.Group>
                            <Form.Group controlId="formPhonenumber">
                              <Form.Label>Phone Number</Form.Label>
                              <Form.Control
                                type="text"
                                name="phonenumber"
                                value={editFormData.phonenumber}
                                onChange={handleInputChange}
                              />
                            </Form.Group>
                            <Form.Group controlId="formAddress">
                              <Form.Label>Address</Form.Label>
                              <Form.Control
                                as="textarea"
                                name="address"
                                value={editFormData.address}
                                onChange={handleInputChange}
                              />
                            </Form.Group>
                            <Button
                              variant="primary"
                              type="submit"
                              className="mt-3"
                            >
                              Update Account
                            </Button>
                          </Form>
                        </div>
                      </Col>
                    </Row>
                  </Card.Text>
                ) : (
                  <p>Loading...</p>
                )}
              </Card.Body>
              <Button variant="success" href="/UserDashboard">
                Back to Dashboard
              </Button>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AccountUpdateForm;
