import { useEffect, useState } from "react";
import {
  Table,
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Modal,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "../Api";
import "../../style/content.css";

function Select() {
  const navigate = useNavigate();
  const [account, setAccount] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentAccount, setCurrentAccount] = useState(null);
  const [editFormData, setEditFormData] = useState({
    username: "",
    name: "",
    gender: "",
    bornplace: "",
    birthdate: "",
    phonenumber: "",
    address: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("authToken");

      if (!token) {
        setIsAuthenticated(false);
        navigate("/LoginForm");
        return;
      }

      try {
        const authResponse = await axios.get("/admin-account", {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (authResponse.data.role !== "admin") {
          navigate("/UserDashboard");
          return;
        }

        setIsAuthenticated(true);

        const dataResponse = await axios.get("/get_data", {
          headers: { Authorization: `Bearer ${token}` },
        });

        setAccount(dataResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        navigate("/LoginForm");
      }
    };

    fetchData();
  }, [navigate]);

  const handleEditModalClose = () => setShowEditModal(false);
  const handleEditModalShow = (account) => {
    setCurrentAccount(account);
    setEditFormData({
      username: account.username,
      name: account.name,
      gender: account.gender,
      bornplace: account.bornplace,
      birthdate: account.birthdate,
      phonenumber: account.phonenumber,
      address: account.address,
    });
    setShowEditModal(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditFormData({ ...editFormData, [name]: value });
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("authToken");

    try {
      await axios.put(`/edit/${currentAccount.id}`, editFormData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      alert("Account edited successfully!");
      // Update the local state with new account data
      setAccount((prevAccounts) =>
        prevAccounts.map((acc) =>
          acc.id === currentAccount.id ? { ...acc, ...editFormData } : acc
        )
      );
      handleEditModalClose();
    } catch (error) {
      alert("Error during editing!");
      console.error("Error during editing:", error);
    }
  };

  const deleteAccount = async (id) => {
    const token = localStorage.getItem("authToken");
    try {
      await axios.delete(`/delete/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAccount((prevAccounts) => prevAccounts.filter((acc) => acc.id !== id));
      alert("Account deleted successfully!");
    } catch (error) {
      alert("Error during deletion!");
      console.error("Error during deletion:", error);
    }
  };

  return (
    <Container className="py-5">
      <Row className="justify-content-center">
        <Col xs={12} md={10}>
          <Card className="shadow card" style={{ maxHeight: "100vh" }}>
            <Card.Body>
              <Card.Title className="text-center mb-4">
                <b>ALL ACCOUNT WIKIPEDIA GAMES</b>
              </Card.Title>
              <Table striped bordered hover responsive="sm">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Username</th>
                    <th>Name</th>
                    <th>Action</th>
                    <th>Gender</th>
                    <th>Born Place</th>
                    <th>Birth Date</th>
                    <th>Phone Number</th>
                    <th>Address</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {account.map((bk) => (
                    <tr key={bk.id}>
                      <td>{bk.id}</td>
                      <td>{bk.username}</td>
                      <td>{bk.name}</td>
                      <td>
                        <Button
                          className="btn btn-primary"
                          onClick={() => handleEditModalShow(bk)}
                        >
                          Edit
                        </Button>
                        <Button
                          className="btn btn-danger mt-1"
                          onClick={() => deleteAccount(bk.id)}
                        >
                          Delete
                        </Button>
                      </td>
                      <td>{bk.gender}</td>
                      <td>{bk.bornplace}</td>
                      <td>{bk.birthdate}</td>
                      <td>{bk.phonenumber}</td>
                      <td>{bk.address}</td>
                      <td>
                        {new Date(bk.created_at).toLocaleTimeString("en-GB", {
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                          hour12: false,
                        })}{" "}
                        (
                        {new Date(bk.created_at).toLocaleDateString("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "2-digit",
                        })}
                        )
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
          <Col className="d-flex justify-content-center mt-4">
            <Button className="btn btn-primary" href="/AdminDashboard">
              Back to Dashboard
            </Button>
          </Col>
        </Col>
      </Row>

      {/* Edit Modal */}
      <Modal show={showEditModal} onHide={handleEditModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleEditSubmit}>
            <Form.Group>
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                name="username"
                value={editFormData.username}
                // onChange={handleInputChange}
                disabled
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={editFormData.name}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Gender</Form.Label>
              <Form.Control
                type="text"
                name="gender"
                value={editFormData.gender}
                disabled
                className="mb-2"
              />
              <Form.Select
                name="gender"
                value={editFormData.gender}
                onChange={handleInputChange}
              >
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </Form.Select>
            </Form.Group>

            <Form.Group>
              <Form.Label>Born Place</Form.Label>
              <Form.Control
                type="text"
                name="bornplace"
                value={editFormData.bornplace}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Birth Date</Form.Label>
              <Form.Control
                type="date"
                name="birthdate"
                value={editFormData.birthdate}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                name="phonenumber"
                value={editFormData.phonenumber}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                name="address"
                value={editFormData.address}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Button type="submit" className="mt-3">
              Save Changes
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
}

export default Select;
