import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Image,
  Form,
  Table,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom"; // Add react-router-dom for navigation
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import axios from "../Api";
import NavigationBar from "../NavigationBar";

const AdminDashboard = () => {
  const navigate = useNavigate(); // Hook for navigation
  // const [image, setImage] = useState(null);
  const [adminAccount, setAdminAccount] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(true); // Assume user is authenticated initially

  useEffect(() => {
    const checkAuthentication = () => {
      const token = localStorage.getItem("authToken");
      if (!token) {
        setIsAuthenticated(false);
        navigate("/LoginForm"); // Redirect to login if not authenticated
      }
    };

    checkAuthentication();

    if (isAuthenticated) {
      // Fetch the admin account details from the API
      const fetchAdminAccount = async () => {
        const token = localStorage.getItem("authToken");
        try {
          const response = await axios.get("/admin-account", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          setAdminAccount(response.data);
          if (response.data.role !== "admin") {
            alert("Username yang anda input adalah User");
            navigate("/UserDashboard"); // Redirect to user dashboard if not admin
          }
        } catch (error) {
          console.error("Failed to fetch admin account details:", error);
          navigate("/LoginForm");
        }
      };

      fetchAdminAccount();
    }
  }, [isAuthenticated, navigate]);

  const handleLogout = async () => {
    try {
      const token = localStorage.getItem("authToken"); // Use correct token key
      await axios.post(
        "/logout",
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      localStorage.removeItem("authToken"); // Use correct token key
      window.location.href = "/LoginForm"; // Redirect to login page after logout
      alert("You have been logged out!");
    } catch (error) {
      alert("error during logout!");
      console.error("There was an error during logout!", error);
    }
  };

  const handleImageChange = async () => {
    alert("Mengupload Gambar Berhasil!");
  };

  return (
    <div className="page-wrapper">
      <NavigationBar />
      <Container fluid className="content p-3">
        <br />
        <br />
        <h1
          className="text-center text-light mt-2"
          style={{
            fontFamily: "Tahoma",
            fontWeight: "bold",
            textShadow: "2px 2px 4px #000000",
          }}
        >
          ADMINISTRATOR DASHBOARD
        </h1>
        <Row className="d-flex justify-content-center align-items-center">
          <Col xs={12} md={6} lg={6} className="mb-4">
            <Card
              className="shadow-sm d-flex justify-content-center align-items-center text-white"
              style={{
                backgroundColor: "rgba(200, 200, 200, 0.3)",
                border: "1px solid rgba(255,255,255,1.0)",
              }}
            >
              <Card.Title
                className="text-center"
                style={{
                  fontFamily: "Tahoma",
                  fontWeight: "bold",
                  textShadow: "2px 2px 4px #000000",
                }}
              >
                Administrator Account Details
              </Card.Title>
              <Card.Body
                style={{ maxWidth: "750px", fontFamily: "TimesnewRoman" }}
              >
                {adminAccount ? (
                  <Card.Text>
                    <Row>
                      <Col className="text-center p-3 m-2">
                        <Image
                          src={
                            adminAccount?.displayPicture ||
                            "https://media.licdn.com/dms/image/v2/D5603AQH6VmISPewG5w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704992132211?e=1730332800&v=beta&t=VxqfO_Ew59JnzQGxBGmrxSNwePiwHSUdW3jJ3woFCKc"
                          }
                          className="me-1"
                          width={125}
                          height={125}
                        />{" "}
                      </Col>
                      <Col className="d-flex justify-content-center align-items-center">
                        <div
                          className="mx-auto p-3 m-2"
                          style={{
                            border: "1px solid rgba(255,255,255,1.0)",
                            borderRadius: "10px",
                            backgroundColor: "rgba(0, 0, 0, 0.38)",
                            fontFamily: "Tahoma",
                            textShadow: "2px 2px 4px #000000",
                          }}
                        >
                          <Table className="justify-content-center">
                            <tr>
                              <td>Username </td>
                              <td>: {adminAccount.username}</td>
                            </tr>
                            <tr>
                              <td>Name </td>
                              <td>: {adminAccount.name}</td>
                            </tr>
                            <tr>
                              <td>Role </td>
                              <td>: {adminAccount.role}</td>
                            </tr>
                          </Table>
                        </div>
                      </Col>
                      <div className="mt-2 text-center">
                        <Row>
                          <Col>
                            <Form.Group controlId="formFile" className="mb-3">
                              <Form.Label>Upload Image Here</Form.Label>
                              <Form.Control type="file" />
                            </Form.Group>
                            <Button variant="info" onClick={handleImageChange}>
                              <i className="fas fa-image"></i>
                            </Button>
                          </Col>
                          <Col className="align-items-center">
                            <Button
                              variant="success"
                              href="/AccountData"
                              className="m-1"
                            >
                              Setting
                            </Button>
                            <Button
                              variant="danger"
                              onClick={handleLogout}
                              className="m-1"
                            >
                              Logout
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </Row>
                  </Card.Text>
                ) : (
                  <Card.Text>Loading admin account details...</Card.Text>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4} className="mb-4">
            <Card className="shadow-sm movieImage">
              <Card.Img
                className="mt-2"
                variant="top"
                src="https://comtechitservices.com/images/2017/10/11/cms.png"
                alt="Content Dashboard"
              />
              <Card.Body className="text-white">
                <Card.Title>Content Manager</Card.Title>
                <Card.Text>View and manage all of the content.</Card.Text>
                <Button variant="primary" href="/AdminContentDashboard">
                  Go to Content Manager
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={4} className="mb-4">
            <Card className="shadow-sm movieImage">
              <Card.Img
                className="mt-2"
                variant="top"
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4WuE1z4bvEjJP4e7HUFtYLABisVpmWY0qgA&s"
                alt="User Management"
              />
              <Card.Body className="text-white">
                <Card.Title>User Management</Card.Title>
                <Card.Text>
                  Manage user accounts, permissions, and roles.
                </Card.Text>
                <Button variant="primary" href="/AccountData">
                  Go to User Management
                </Button>
              </Card.Body>
            </Card>
          </Col>

          <Col xs={12} md={4} className="mb-4">
            <Card className="shadow-sm movieImage">
              <Card.Img
                className="mt-2"
                variant="top"
                src="https://img.freepik.com/premium-vector/comments-icon_159242-10532.jpg"
                alt="Admin Comments Manager"
              />
              <Card.Body className="text-white">
                <Card.Title>Comments Manager</Card.Title>
                <Card.Text>View and manage all of the comments.</Card.Text>
                <Button variant="primary" href="/AdminCommentsData">
                  Go to Comment Manager
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AdminDashboard;
