import React, { useState, useEffect } from "react";
import axios from "../Api"; // Ensure this is correctly set up in your project
import { useNavigate } from "react-router-dom";
import { Button, Card, Container, Row, Col } from "react-bootstrap";
import NavigationBar from "../NavigationBar";
import PUBGMobileAdmin from "../components_pubgm/PUBGMobileAdmin";
import MobileLegendAdmin from "../components_mobile_legend/MobileLegendAdmin";
import FreeFireAdmin from "../components_freefire/FreeFireAdmin";

const AdminContentDashboard = () => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Start with false by default
  const [adminAccount, setAdminAccount] = useState(null); // Initialize state for admin account

  useEffect(() => {
    const checkAuthentication = () => {
      const token = localStorage.getItem("authToken");
      if (!token) {
        setIsAuthenticated(false);
        navigate("/LoginForm"); // Redirect to login if not authenticated
        return; // Exit the function early
      }

      setIsAuthenticated(true); // Set as authenticated if token exists
    };

    checkAuthentication();

    const fetchAdminAccount = async () => {
      if (!isAuthenticated) return; // Don't fetch if not authenticated

      const token = localStorage.getItem("authToken");
      try {
        const response = await axios.get("/admin-account", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          const data = response.data;
          setAdminAccount(data);
          if (data.role !== "admin") {
            navigate("/UserDashboard"); // Redirect to user dashboard if not admin
          } else {
            setIsAuthenticated(true); // Set as authenticated only if admin
          }
        } else {
          console.error(
            "Failed to fetch admin account details:",
            response.statusText
          );
          navigate("/LoginForm");
        }
      } catch (error) {
        console.error(
          "Failed to fetch admin account details:",
          error.message
        );
        navigate("/LoginForm");
      }
    };

    fetchAdminAccount();
  }, [isAuthenticated, navigate]);

  return (
    <div>
      <NavigationBar />
      <br />
      <Row>
        <div
          className="d-flex justify-content-center mt-5"
          style={{ fontFamily: "Tahoma" }}
        >
          <Col>
            <Card.Title>
              <h1
                className="text-center text-white"
                style={{
                  fontFamily: "Tahoma",
                  textShadow: "2px 2px 4px #000000",
                }}
              >
                <b>Mobile Legend Manager</b>
              </h1>
            </Card.Title>
            <MobileLegendAdmin />
            <Card.Title>
              <h1
                className="text-center text-white"
                style={{
                  fontFamily: "Tahoma",
                  textShadow: "2px 2px 4px #000000",
                }}
              >
                <b>PUBG Mobile Manager</b>
              </h1>
            </Card.Title>
            <PUBGMobileAdmin />
            <Card.Title>
              <h1
                className="text-center text-white"
                style={{
                  fontFamily: "Tahoma",
                  textShadow: "2px 2px 4px #000000",
                }}
              >
                <b>Free Fire Manager</b>
              </h1>
            </Card.Title>
            <FreeFireAdmin />
            <Col className="d-flex justify-content-center m-4">
              <Button className="btn btn-primary" href="/AdminDashboard">
                Back to Dashboard
              </Button>
            </Col>
          </Col>
        </div>
      </Row>
    </div>
  );
};

export default AdminContentDashboard;
