import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Image,
  Table,
  Form,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import axios from "../Api"; // Assuming you have an axios instance with API base URL
import NavigationBar from "../NavigationBar";

const UserDashboard = () => {
  const [userAccount, setUserAccount] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null); // For handling file upload
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthentication = () => {
      const token = localStorage.getItem("authToken");
      if (!token) {
        setIsAuthenticated(false);
        navigate("/LoginForm");
      } else {
        setIsAuthenticated(true);
      }
    };

    checkAuthentication();

    if (isAuthenticated) {
      // Fetch the user account details from the API
      const fetchUserAccount = async () => {
        try {
          const token = localStorage.getItem("authToken");
          const response = await axios.get("/user-account", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          setUserAccount(response.data);
          if (response.data.role !== "user") {
            alert("The username you input is Admin.");
            navigate("/UserDashboard"); // Redirect to user dashboard if not user
          }
        } catch (error) {
          console.error("Failed to fetch user account details:", error);
          navigate("/LoginForm");
        }
      };

      fetchUserAccount();
    }
  }, [isAuthenticated, navigate]);

  const handleLogout = async () => {
    try {
      const token = localStorage.getItem("authToken");
      await axios.post(
        "/logout",
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      localStorage.removeItem("authToken");
      window.location.href = "/LoginForm";
      alert("You have been logged out!");
    } catch (error) {
      alert("Error during logout!");
      console.error("There was an error during logout!", error);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.size > 2048 * 1024) {
      // 2MB size limit
      alert("File size exceeds 2MB.");
      setSelectedFile(null); // Reset file selection
    } else {
      setSelectedFile(file);
    }
  };

  const handleImageUpload = async () => {
    if (!selectedFile) {
      alert("Please select a file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("display_picture", selectedFile); // Ensure this matches the backend expectation

    try {
      const token = localStorage.getItem("authToken");
      const response = await axios.post("/upload-image", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        // Update the user account with the new profile picture
        setUserAccount((prevAccount) => ({
          ...prevAccount,
          display_picture: response.data.file_path, // Update with the correct path returned by backend
        }));
        alert("Profile picture uploaded successfully!");
      }
    } catch (error) {
      console.error("Error uploading profile picture:", error);
      alert("Failed to upload the profile picture.");
    }
  };

  return (
    <div className="page-wrapper">
      <NavigationBar />
      <Container fluid className="content p-3">
        <br />
        <br />
        <h1
          className="text-center text-light mt-2"
          style={{
            fontFamily: "Tahoma",
            fontWeight: "bold",
            textShadow: "2px 2px 4px #000000",
          }}
        >
          <b>USER DASHBOARD</b>
        </h1>
        <Row className="d-flex justify-content-center align-items-center">
          <Col xs={12} md={6} lg={6} className="mb-4">
            <Card
              className="shadow-sm d-flex justify-content-center align-items-center text-white"
              style={{
                backgroundColor: "rgba(200, 200, 200, 0.3)",
                border: "1px solid rgba(255,255,255,1.0)",
              }}
            >
              <Card.Title
                className="text-center"
                style={{
                  fontFamily: "Tahoma",
                  fontWeight: "bold",
                  textShadow: "2px 2px 4px #000000",
                }}
              >
                <b>User Account Details</b>
              </Card.Title>
              <Card.Body
                style={{ maxWidth: "750px", fontFamily: "TimesnewRoman" }}
              >
                {userAccount ? (
                  <Card.Text>
                    <Row>
                      <Col className="text-center p-3 m-2">
                        <Image
                          src={
                            "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?size=626&ext=jpg" ||
                            userAccount.display_picture
                          }
                          className="me-1"
                          width={125}
                          height={125}
                          rounded
                        />
                      </Col>
                      <Col className="d-flex justify-content-center align-items-center">
                        <div
                          className="mx-auto p-3 m-2"
                          style={{
                            border: "1px solid rgba(255,255,255,1.0)",
                            borderRadius: "10px",
                            backgroundColor: "rgba(0, 0, 0, 0.38)",
                            fontFamily: "Tahoma",
                            textShadow: "2px 2px 4px #000000",
                          }}
                        >
                          <Table className="justify-content-center">
                            <tr>
                              <td>Username</td>
                              <td>: {userAccount.username}</td>
                            </tr>
                            <tr>
                              <td>Name</td>
                              <td>: {userAccount.name}</td>
                            </tr>
                            <tr>
                              <td>Role</td>
                              <td>: {userAccount.role}</td>
                            </tr>
                          </Table>
                        </div>
                      </Col>
                      <div className="mt-2 text-center">
                        <Row>
                          <Col className="align-items-center">
                            <Button variant="success" className="m-1" href="/AccountUpdateForm">
                              Settings
                            </Button>
                            <Button
                              variant="danger"
                              onClick={handleLogout}
                              className="m-1"
                            >
                              Logout
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </Row>
                  </Card.Text>
                ) : (
                  <Card.Text>Loading user account details...</Card.Text>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4} className="mb-4 container-fluid">
            <Card className="shadow-sm movieImage">
              <Card.Img
                className="mt-2"
                variant="top"
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4WuE1z4bvEjJP4e7HUFtYLABisVpmWY0qgA&s"
                alt="User Management"
              />
              <Card.Body className="text-white">
                <Card.Title>User Management</Card.Title>
                <Card.Text>Manage user accounts for update.</Card.Text>
                <Button variant="primary" href="/AccountUpdateForm">
                  Go to User Management
                </Button>
              </Card.Body>
            </Card>
          </Col>

          <Col xs={12} md={4} className="mb-4 container-fluid">
            <Card className="shadow-sm movieImage">
              <Card.Img
                className="mt-2"
                variant="top"
                src="https://img.freepik.com/premium-vector/comments-icon_159242-10532.jpg"
                alt="Admin Comments Manager"
              />
              <Card.Body className="text-white">
                <Card.Title>Comments Manager</Card.Title>
                <Card.Text>View all of the comments.</Card.Text>
                <Button variant="primary" href="/UserCommentsData">
                  Go to Comment Manager
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UserDashboard;
