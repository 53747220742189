import { useState, useEffect, useRef } from "react";
import { Form, Container, Row, Col, Button, Card } from "react-bootstrap";
import _ from "lodash";
import "../../style/content.css";
import axios from "../Api";

const Insert = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [bornplace, setBornplace] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [address, setAddress] = useState("");
  const [role, setRole] = useState("");
  const [messages, setMessages] = useState({});
  const [successMessage, setSuccessMessage] = useState(""); // State for success message

  // Reference for ResizeObserver
  const containerRef = useRef(null);

  useEffect(() => {
    const handleResize = _.debounce(() => {
      console.log("Resize observed");
    }, 200);

    if (containerRef.current) {
      const resizeObserver = new ResizeObserver(handleResize);
      resizeObserver.observe(containerRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  const validateFields = () => {
    const newMessages = {};
    if (!username) newMessages.username = "Username is required.";
    if (!password) newMessages.password = "Password is required.";
    if (!name) newMessages.name = "Name is required.";
    if (!gender) newMessages.gender = "Gender is required.";
    if (!bornplace) newMessages.bornplace = "Born place is required.";
    if (!birthdate) newMessages.birthdate = "Birth date is required.";
    if (!phonenumber) newMessages.phonenumber = "Phone number is required.";
    if (!address) newMessages.address = "Address is required.";
    if (!role) newMessages.role = "Role is required.";

    setMessages(newMessages);

    return Object.keys(newMessages).length === 0;
  };

  const addAccount = () => {
    if (!validateFields()) {
      return; // If validation fails, do not proceed
    }

    const newAccount = {
      username,
      password,
      name,
      gender,
      bornplace,
      birthdate,
      phonenumber,
      address,
      role,
      done: false,
    };

    console.log("New account data:", newAccount);

    axios
      .post("/proses_insert", newAccount)
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          // Clear form fields
          setUsername("");
          setPassword("");
          setName("");
          setGender("");
          setBornplace("");
          setBirthdate("");
          setPhonenumber("");
          setAddress("");
          setRole("");

          // Set success message
          setSuccessMessage("Add New Account Success!");
          setMessages({}); // Clear messages on success
        } else {
          throw new Error(response.data.message || "Failed to add account");
        }
      })
      .catch((error) => {
        console.error("Error adding account:", error);
        setMessages({
          form: "Failed to add New Account. Please try again later.",
        });
        setSuccessMessage(""); // Clear success message on error
      });
  };

  return (
    <Container
      className="d-flex justify-content-center align-items-center min-vh-100 custom-container"
      ref={containerRef}
    >
      <Row className="w-100">
        <Col xs={12} md={8} lg={6} xl={4} className="mx-auto">
          <Card className="p-4 shadow-sm loginBG" style={{ maxHeight: "75vh" }}>
            <Card.Body>
              <Card.Title className="text-center Titel">
                REGISTER ACCOUNT
              </Card.Title>
              <Form>
                <Form.Group controlId="inputName" className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    isInvalid={!!messages.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {messages.name}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="inputGender" className="mb-3">
                  <Form.Label>Gender</Form.Label>
                  <div className="d-flex">
                    <Form.Check
                      type="radio"
                      id="genderMale"
                      name="gender"
                      label="Male"
                      value="male"
                      checked={gender === "male"}
                      onChange={(e) => setGender(e.target.value)}
                      className="me-3"
                      isInvalid={!!messages.gender}
                    />
                    <Form.Check
                      type="radio"
                      id="genderFemale"
                      name="gender"
                      label="Female"
                      value="female"
                      checked={gender === "female"}
                      onChange={(e) => setGender(e.target.value)}
                      isInvalid={!!messages.gender}
                    />
                    <Form.Control.Feedback type="invalid">
                      {messages.gender}
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
                <Form.Group controlId="inputBornplace" className="mb-3">
                  <Form.Label>Born Place</Form.Label>
                  <Form.Control
                    type="text"
                    value={bornplace}
                    onChange={(e) => setBornplace(e.target.value)}
                    isInvalid={!!messages.bornplace}
                  />
                  <Form.Control.Feedback type="invalid">
                    {messages.bornplace}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="inputBirthdate" className="mb-3">
                  <Form.Label>Birth Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={birthdate}
                    onChange={(e) => setBirthdate(e.target.value)}
                    isInvalid={!!messages.birthdate}
                  />
                  <Form.Control.Feedback type="invalid">
                    {messages.birthdate}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="inputPhonenumber" className="mb-3">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="number"
                    value={phonenumber}
                    onChange={(e) => setPhonenumber(e.target.value)}
                    isInvalid={!!messages.phonenumber}
                  />
                  <Form.Control.Feedback type="invalid">
                    {messages.phonenumber}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="inputAddress" className="mb-3">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    isInvalid={!!messages.address}
                  />
                  <Form.Control.Feedback type="invalid">
                    {messages.address}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="inputUsername" className="mb-3">
                  <Form.Label>Username (ID)</Form.Label>
                  <Form.Control
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    isInvalid={!!messages.username}
                  />
                  <Form.Control.Feedback type="invalid">
                    {messages.username}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="inputRole" className="mb-3">
                  <Form.Label>Role</Form.Label>
                  <Form.Select
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    isInvalid={!!messages.role}
                  >
                    <option value="">Select Role</option>
                    <option value="user">User</option>
                    <option value="admin" disabled>
                      Admin
                    </option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {messages.role}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="inputPassword" className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    isInvalid={!!messages.password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {messages.password}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Text className="formText" muted>
                  Your password must be 8-20 characters long, contain letters
                  and numbers, and must not contain spaces, special characters,
                  or emoji.
                </Form.Text>
                <Button
                  variant="primary"
                  className="w-100 add-button"
                  onClick={addAccount}
                  type="button"
                >
                  Sign Up
                </Button>
                <Button
                  variant="success"
                  className="w-100 add-button"
                  href="/LoginForm"
                >
                  Back to Login
                </Button>
              </Form>
              {successMessage && (
                <div className="alert alert-success mt-3">{successMessage}</div>
              )}
              {messages.form && (
                <div className="alert alert-danger mt-3">{messages.form}</div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Insert;
